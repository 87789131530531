<template>
    <gk-searchbar
        v-model:form="searchForm"
        :config="searchConfig"
        :fold-display-number="3"
        :foldable="true"
        :options="searchOptions"
        @reset="getDataList"
        @search="getDataList"
    />
    <div class="common-list">
        <div class="button-wapper">
            <!--            <el-button type="primary" @click="openEditDialog()">新增</el-button>-->
            <!--<el-button type="danger" @click="onDelete()">删除</el-button>-->
        </div>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column type="selection" width="55" />
                <el-table-column label="ID" prop="id" />
                <el-table-column label="权限名称" prop="name" />
                <el-table-column label="权限状态">
                    <template #default="scope">
                        {{ scope.row.status === 1 ? "启用" : "禁用" }}
                    </template>
                </el-table-column>
                <el-table-column label="是否是菜单">
                    <template #default="scope">
                        {{ scope.row.is_menu ? "是" : "否" }}
                    </template>
                </el-table-column>
                <!--                <el-table-column label="操作" width="150">-->
                <!--                    <template #default="scope">-->
                <!--                        <el-button plain size="mini" type="primary" @click="openEditDialog(scope.row)">-->
                <!--                            编辑-->
                <!--                        </el-button>-->
                <!--                        <el-button plain size="mini" type="danger" @click="onDelete(scope.row.id)">删除</el-button>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
            </el-table>
        </div>
    </div>
    <!--<role-edit :id="editDialog.id" v-model:show="editDialog.show" :detail="editDialog.detail" @update="getDataList" />-->
</template>

<script setup>
import { onMounted, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { DELETE_PERMISSION, GET_PERMISSION_LIST } from "@/api/permission";
import useCommonList from "../../../../hooks/useCommonList";

let { dataList, selectionIds, changeSelectionIds } = useCommonList();

onMounted(() => {
    getDataList();
});

// 搜索项
let searchConfig = ref([]);

let searchOptions = ref({});

let searchForm = ref({});

// 获取数据
let getDataList = () => {
    GET_PERMISSION_LIST({
        ...searchForm.value
    }).then((res) => {
        dataList.value = res.data.data.list;
        console.log(res.data.data);
    });
};

// 增删改
let editDialog = ref({
    show: false,
    id: null,
    detail: null
});

let openEditDialog = (item = null) => {
    editDialog.value = {
        show: true,
        id: item ? item.id : null,
        detail: item
    };
};

let onDelete = (id = null) => {
    // if (id) selectionIds.value = [id];
    ElMessageBox.confirm("确认删除该用户？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
    }).then(() => {
        DELETE_PERMISSION({ id: id }).then((res) => {
            if (res.data.code === 200) {
                getDataList();
                ElMessage.success("删除成功");
            }
        });
    });
};
</script>
